import { useSelector } from 'react-redux';
import { TagType } from '../../../__types__';
import { RootStore } from '../../reducer';
import { __selectTagsList } from './tags.selectors';

/**
 * Returns a list of tags of a specific type
 */
export const useTags = (type: TagType) =>
  useSelector((state: RootStore) => __selectTagsList(state, type));
