import { ErrorMutationData } from '@passionware/monads';
import { ReactNode } from 'react';
import { cn } from '../platform/dom/cn';

function getMessage(error: Error) {
  // if (error instanceof TranslatedError) {
  //   return i18n.t(...error.options);
  // }
  return error.message;
}

export const createErrorRenderer = (className: string) => (error: Error) => {
  const message = getMessage(error);
  return (
    <div
      className={cn(
        'min-w-20 w-fit h-[1lh] text-countful-dark-red bg-countful-light-red-original px-1 text-sm',
        'rounded-sm',
        className
      )}
    >
      <div>{message}</div>
    </div>
  );
};

export const renderSmallError =
  (className: string, genericContent?: ReactNode) =>
  (error: Error | ErrorMutationData<unknown>) => {
    const message = getMessage(error instanceof Error ? error : error.error);
    return (
      <div
        className={cn(
          'text-countful-dark-red bg-countful-light-red-original border border-countful-dark-red px-1 text-sm h-[1lh]',
          'flex flex-row justify-center items-center',
          'rounded-sm',
          className
        )}
        data-tip={message}
      >
        {genericContent}
      </div>
    );
  };

export const renderError = createErrorRenderer('');
