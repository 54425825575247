import { Shortlist, ShortlistBlock } from '__types__/index';
import { pick } from 'lodash';

export const createShortlistUpdatePayload = (data: Partial<Shortlist>) => {
  const toSubmit = pick(data, [
    'status',
    'title',
    'description',
    'default_resources_query',
    'public_title',
    'public_description',
    'private_password',
    'expanded',
    'enable_notifications',
    'public',
    'tags',
    'published',
    'enable_commenting',
    'enable_voting',
    'public_feedback',
    'default_fields',
    'layout',
    'archived',
    'show_availability',
    'user_id'
  ]);

  if (data.shortlist_blocks) {
    const shortlist_blocks =
      data.shortlist_blocks &&
      data.shortlist_blocks.map(block => {
        const maybeGhost = block as unknown as { id?: unknown };
        if (
          typeof maybeGhost.id === 'string' &&
          maybeGhost.id.includes('temp')
        ) {
          delete maybeGhost.id;
        }
        const shortlist_block = pick(block, [
          'id',
          'shortlist_id',
          'order',
          'data',
          'contact_id',
          'archived',
          'pins'
        ]);
        return shortlist_block;
      });

    return {
      ...toSubmit,
      shortlist_blocks
    };
  }
  return toSubmit;
};

// copy-paste from shortlist.action.js
export const createShortlistBlockUpdatePayload = (
  data: Partial<ShortlistBlock>
) => {
  const toSubmit = pick(data, [
    'contact_id',
    'archived',
    'data',
    'id',
    'order'
  ]);
  const pins = data.pins?.map(pin => {
    if (typeof pin.id === 'string' && pin.id.includes('temp')) {
      delete pin.id;
    }
    return pick(pin, ['file_id', 'pin_type', 'url', 'order', 'id']);
  });
  return {
    ...toSubmit,
    pins
  };
};

export type ShortlistBlockCreationPayload = Pick<
  ShortlistBlock,
  'contact_id' | 'data' | 'order' | 'pins'
>;

export const createShortlistBlockCreatePayload = <
  T extends ShortlistBlockCreationPayload
>(
  data: T
) => {
  return pick(data, ['contact_id', 'data', 'order', 'pins']);
};
