import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const twMergePrefixed = extendTailwindMerge({
  prefix: 'tw-'
});

export function cn(...inputs: ClassValue[]) {
  return twMergePrefixed(clsx(inputs));
}
