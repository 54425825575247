import { orderBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { RootStore } from 'store/reducer';
import { TagType } from '../../../__types__';

const selectTagsStore = (state: RootStore) => state.tags;

// not used directly in the app, returns a map of tags (keyed by id)
const selectTags = createSelector(selectTagsStore, tags => tags.data);

// Tags are always ordered alphabetically and filtered by tagType
// Please use useTags hook instead of this selector
const __selectTagsList = createSelector(
  selectTags,
  (_: RootStore, tagType: TagType) => tagType,
  (data, tagType) => {
    const filteredTags = Object.values(data).filter(
      tag => tag.tag_type === tagType
    );
    return orderBy(filteredTags, [tag => tag.tag.toLowerCase()]);
  }
);

const selectTagById = createCachedSelector(
  selectTags,
  (_: RootStore, id: number) => id,
  (data, id) => data[id]
)((_, id) => id);

export { selectTagsStore, selectTags, __selectTagsList, selectTagById };
