import { rd } from '@passionware/monads';
import { createMatcher } from '@passionware/query-toolkit';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useTags } from 'store/v1/tags/tags.hooks';
import Store from '../../store';
import { __selectTagsList, createTag } from '../../store/v1/tags';
import { TagsService } from './TagsService';

export function createTagsService(store: typeof Store): TagsService {
  return {
    useTags: query => {
      const allTagsOfType = useTags(query.type);
      const matchingTags = allTagsOfType.filter(
        createMatcher(query.search, x => x.tag)
      );
      return rd.of(matchingTags);
    },
    addTag: async (type, tag) => {
      // @ts-ignore unwrap
      return await store.dispatch(createTag({ tag, tag_type: type })).unwrap();
    },
    useSelectedTags: (type, tagValues) => {
      const allTags = useTags(type);

      return useMemo(() => {
        let missingCount = 0;
        const tagByValue = keyBy(allTags, 'tag');
        return rd.of(
          tagValues.map(tag => tagByValue[tag] ?? { id: missingCount++, tag })
        );
      }, [allTags, tagValues]);
    },
    resolveById: async (type, id) => {
      const tags = __selectTagsList(store.getState(), type);
      const existingTag = tags.find(x => x.id === id);
      if (existingTag) {
        return existingTag;
      }
      return new Promise((resolve, reject) => {
        const unsubscribe = store.subscribe(() => {
          const tags = __selectTagsList(store.getState(), type);
          const existingTag = tags.find(x => x.id === id);
          unsubscribe();
          if (existingTag) {
            resolve(existingTag);
          } else {
            reject('Tag not found');
          }
        });
      });
    }
  };
}
