import { maybe, rd } from '@easle/monads';
import Account from '../../lib/auth/Account';
import { StudioService } from '../StudioService/StudioService';
import { ArrivalListService } from './ArrivalListService';

export function createArrivalListService(
  studioService: StudioService
): ArrivalListService {

  return {
    useArrivalList: () => {
      const workspace = maybe.getOrThrow(
        Account.resolveFirstWorkspace(),
        'failed to find default workspace'
      );
      const tenant = maybe.getOrThrow(Account.tenant(), 'failed to find tenant');
      const domain = (tenant as any).web_url;
      const dbSchema = workspace.db_schema;

      const studios = studioService.useStudioList();
      return rd.map(studios, studios =>
        studios.map(studio => ({
          studio: studio.name,
          portraitUrl: `${domain}/app/${dbSchema}/arrivals?studio=${studio.id}`,
          landscapeUrl: `${domain}/app/${dbSchema}/arrivals/landscape?studio=${studio.id}`
        }))
      );
    }
  };
}
