import { Resource } from '__types__';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContact } from 'store/v1/contacts/contacts.actions';

interface GenericSearchFilterItemProps {
  label: string;
  filter: any;
  filterType: string;
  handleRemoveFilter: (params: object) => void;
  params: any;
}

const GenericSearchFilterItem: React.FC<GenericSearchFilterItemProps> = ({
  filter,
  filterType,
  handleRemoveFilter,
  params
}) => {
  const dispatch = useDispatch();
  const contacts = useSelector((state: any) => state.contacts);
  const [contact, setContact] = useState<Resource | null>(null);

  useEffect(() => {
    if (filterType === 'contact_id' && !get(contacts, ['data', filter.eq])) {
      dispatch(getContact(filter.eq));
    }
  }, [filterType]);

  useEffect(() => {
    if (
      filterType === 'contact_id' &&
      get(contacts, ['data', filter.eq]) &&
      (!contact || contact.id !== filter.eq)
    ) {
      // set contact if not set or if contact id has changed
      setContact(get(contacts, ['data', filter.eq]));
    }
  }, [contacts]);

  const renderLabel = () => {
    switch (filterType) {
      case 'user_id':
        return 'Belongs to me';
      case 'created_at':
        return `Created after ${filter.gte}`;
      case 'published':
        return `${filter.eq ? 'Published Only' : 'Drafts Only'}`;
      case 'archived':
        return `${filter.eq ? 'Show Archived' : ''}`;
      case 'contact_id':
        return `${filter.eq ? `Resource ${contact?.full_name}` : ''}`;
      case 'production_dates':
      case 'events':
        return `Between ${moment(filter.gte).format('Do MMMM')} - ${moment(
          filter.lte
        ).format('Do MMMM')}`;
      case 'tags':

        function truncate(tags: string[], n: number) {
          if (tags.length > n) {
            return (
              tags.slice(0, n).join(', ') +
              ' and ' +
              (tags.length - n) +
              ' more'
            );
          }
          return tags.join(', ');
        }

        if (filter.any) {
          return `Any tag: ${truncate(filter.any, 3)}`;
        }
        if (filter.match) {
          return `Match tags: ${truncate(filter.match, 3)}`;
        }
        return '??';
      default:
        return null;
    }
  };

  return (
    <span key={filter.id} className="PageSearch-activeFilters-item">
      {renderLabel()}{' '}
      <span
        className="PageSearch-activeFilters-item-remove"
        onClick={() => {
          handleRemoveFilter(params);
        }}
      >
        <img src="/images/icon_close_white.svg" alt="" />
      </span>
    </span>
  );
};

export default GenericSearchFilterItem;
