import {myArrivalListService} from './ArrivalListService/ArrivalListService.connected';
import {myStudioService} from './StudioService/StudioService.connected';
import {myTagsService} from './TagsService/TagsService.connected';

/**
 * Stable reference to all services in the app.
 * This is used to inject services into components.
 * In the greenfield code, we have a root widget that receives stable services reference and pass it transparently down to all widgets.
 */
export const myServices = {
  arrivalListService: myArrivalListService,
  studioService: myStudioService,
  tagsService: myTagsService
};
