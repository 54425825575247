import { rd } from '@passionware/monads';
import { Tag, TagType } from '../../../__types__';
import { TagsService } from '../../../services/TagsService/TagsService';
import {
  CollectionField,
  CollectionFieldProps
} from '../../common-widgets/CollectionField';
import { useDefaultCreateContent } from '../../common-widgets/ItemSelector';
import { injectConfig } from '../../platform/react/injectConfig';

export type TagsFieldProps = Omit<CollectionFieldProps<Tag>, 'config'> & {
  services: {
    tagsService: TagsService;
  };
  tagType: TagType;
};

export const TagsField = injectConfig(CollectionField<Tag>)
  .fromProps<TagsFieldProps>(api => ({
    useOptions: query => {
      const props = api.useProps();
      const tagsMatchingQuery = props.services.tagsService.useTags({
        type: 'shortlist',
        search: query
      });

      return rd.useMemoMap(tagsMatchingQuery, x =>
        x.map(x => ({ id: x.id.toString(), label: x.tag }))
      );
    },
    useGetGhostItem: () => {
      const props = api.useProps();
      return query => ({ id: -1, tag: query, tag_type: props.tagType });
    },
    getId: x => x.id.toString(),
    rowRenderer: x => <div>{x.tag}</div>,
    useCreateContent: (query, options) =>
      useDefaultCreateContent(
        query,
        options,
        <>
          Create tag named: <strong>{query}</strong>
        </>
      ),
    createPromise: query => {
      const props = api.useProps();
      return props.services.tagsService.addTag(props.tagType, query);
    },
    promptRenderer: data => (data.length === 0 ? 'Add tag' : 'Add'),
    resolveById: id => {
      const props = api.useProps();
      return props.services.tagsService.resolveById(props.tagType, Number(id));
    },
    useSelectedItems: data => {
      const props = api.useProps();
      return props.services.tagsService.useSelectedTags(props.tagType, data);
    }
  }))
  .transformProps(x => x.skipFields('tagType', 'services'));
