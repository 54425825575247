import React from 'react';
import { SaveStatus, SystemLink, SystemUser } from 'v1/components/shared';

import './PageHeader.scss';
import { ConnectedOwnerPicker } from '~/v4/pages/users/UserPicker.owner.connected';
import { systemUserClasses } from '~/v1/components/shared/byModel/User/SystemUser/systemUserPickerClasses';
import classnames from 'classnames';

const PageHeader = props => {
  const renderOwner = () => {
    const { user_id } = props.meta;

    if (user_id) {
      return (
        <div className="PageHeader-meta">
          <div className="PageHeader-meta-user">
            {props.onOwnerSelect ? (
              // todo disconnect: turn PageHeader into factory and create a new component with given configuration
              <ConnectedOwnerPicker
                onSelect={props.onOwnerSelect}
                tooltip={props.ownerTooltip}
              >
                <SystemUser
                  tabIndex={0}
                  id={user_id}
                  includeImage
                  className={systemUserClasses}
                />
              </ConnectedOwnerPicker>
            ) : (
              <SystemUser id={user_id} includeImage />
            )}
          </div>
        </div>
      );
    }
  };

  const renderTags = () => {
    const { tags = [] } = props.meta;

    function truncate(tags, n) {
      if (tags.length > n) {
        return {
          displayedTags: tags.slice(0, n),
          overflowedTags: tags.length - n
        };
      }

      return {
        displayedTags: tags,
        overflowedTags: 0
      };
    }

    const { displayedTags, overflowedTags } = truncate(tags, 4);

    if (tags && tags.length) {
      return (
        <div className="tw-flex tw-px-[5px] tw-gap-1 tw-flex-wrap">
          {displayedTags.map(tag => (
            <span
              key={tag.id}
              className="tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-rounded tw-bg-gray-200 tw-py-[3px] tw-px-[5px] tw-text-gray-600"
            >
              {tag.tag}
            </span>
          ))}
          {overflowedTags > 0 && (
            <span className="tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-rounded tw-bg-gray-200 tw-py-[3px] tw-px-[5px] tw-text-gray-600">
              +{overflowedTags} more
            </span>
          )}
        </div>
      );
    }
  };

  const {
    backLink,
    //backLabel,
    title,
    //subtitle,
    meta,
    children,
    updatingStatus,
    onPanelClick,
    beta
  } = props;

  const renderHeaderInfoPanel = () => {
    const WrapperComponent = onPanelClick ? 'button' : 'div';

    return (
      <WrapperComponent
        onClick={() => onPanelClick && onPanelClick()}
        className={classnames('tw-py-1', {
          'tw-border-none tw-bg-transparent tw-cursor-pointer tw-rounded hover:tw-bg-gray-100 focus:tw-bg-gray-100 ':
            WrapperComponent === 'button',
          'tw-bg-inherit tw-cursor-default': WrapperComponent === 'div'
        })}
      >
        <div className="PageHeader-mainTitle-title">
          <h3 className="trunc" title={title}>
            {title}
            {beta && <span className="PageHeader-beta">Beta</span>}
          </h3>
          {updatingStatus && (
            <SaveStatus
              loading={updatingStatus.isUpdating}
              updated={updatingStatus.updated}
            />
          )}
        </div>
        {meta && renderTags()}
      </WrapperComponent>
    );
  };

  return (
    <div className="PageHeader">
      <div className="PageHeader-mainTitle">
        {backLink && (
          <SystemLink to={backLink} className="btn btn-link btn-square">
            <img
              src="/images/icon_arrow_right_gray.svg"
              className="flip"
              width="10px"
              alt=""
            />
          </SystemLink>
        )}
        {renderHeaderInfoPanel()}
      </div>
      {meta && renderOwner()}
      <div className="PageHeader-buttons text-right">{children}</div>
    </div>
  );
};

export default PageHeader;
