import React, { Component } from 'react';

import {
  Grid,
  GridCell,
  MultiResourceSelector,
  Select,
  SuggestedTextInput,
  TagInput
} from 'v1/components/shared';
import DEPRECATED_TextInput from 'v1/components/legacy/deprecated/DEPRECATED_TextInput/DEPRECATED_TextInput';

import Geosuggest from 'react-geosuggest';
import classnames from 'classnames';
import {
  convertToLowestDenomination,
  convertToWholeUnit
} from 'v1/helpers/currencyHelper';
import chain, { debounce, find, get, isEmpty } from 'lodash';
import { isValue } from 'v1/helpers/misc';
import './SearchField.scss';

export const SEARCH_TYPES = {
  LONG_TEXT: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  PERCENTAGE: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
    // Greater / Lower then does not play nice when allowing decimals on PERCENTAGE fields
    // Will remove ability for now as its unlikely customers use this.
    // {
    //   label: 'Greater than',
    //   value: 'gte',
    //   text: 'is greater than'
    // },
    // {
    //   label: 'Lower than',
    //   value: 'lte',
    //   text: 'is less than'
    // }
  ],
  SHORT_TEXT: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Starts with',
      value: 's_with',
      text: 'starts with'
    },
    {
      label: 'Ends with',
      value: 'e_with',
      text: 'ends with'
    },
    {
      label: 'Contains',
      value: 'contains',
      text: 'contains',
      disabled: true
    }
  ],
  BOOLEAN: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  NUMBER: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Greater than',
      value: 'gte',
      text: 'is greater than'
    },
    {
      label: 'Lower than',
      value: 'lte',
      text: 'is less than'
    }
  ],
  CURRENCY: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Greater than',
      value: 'gte',
      text: 'is greater than'
    },
    {
      label: 'Lower than',
      value: 'lte',
      text: 'is less than'
    }
  ],
  MULTI_SELECT: [
    {
      label: 'Match',
      value: 'match',
      text: 'has one of below'
    },
    {
      label: 'Any',
      value: 'any',
      text: 'has any of below'
    }
  ],
  TAG_SELECT: [
    {
      label: 'Match',
      value: 'match',
      text: 'has one of below'
    },
    {
      label: 'Any',
      value: 'any',
      text: 'has any of below'
    }
  ],
  RESOURCE_SELECT: [
    {
      label: 'Match',
      value: 'match',
      text: 'has one of below'
    }
  ],
  SINGLE_SELECT: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  DATE: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  DATE_RANGE: [],
  URL: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  MEASUREMENT: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Greater than',
      value: 'gte',
      text: 'is greater than'
    },
    {
      label: 'Lower than',
      value: 'lte',
      text: 'is less than'
    }
  ],
  DIMENSION_2D: [],
  DIMENSION_3D: [],
  NETSUITE_MASTER_PROJECT: [],
  NETSUITE_SUBCLASS: [],
  NETSUITE_VENDOR: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  HEIGHT: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Greater than',
      value: 'gte',
      text: 'is greater than'
    },
    {
      label: 'Lower than',
      value: 'lte',
      text: 'is less than'
    }
  ],
  NETSUITE_PO: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ]
};
const FIELD_TYPES = {
  type: { search_type: 'BOOLEAN', field_type: 'text' },
  skills: { search_type: 'MULTI_SELECT', field_type: 'match' },
  tags: { search_type: 'TAG_SELECT', field_type: 'match' },
  location: { search_type: 'RESOURCE_SELECT', field_type: 'match' },
  rates: { search_type: 'NUMBER', field_type: 'number' },
  list: { search_type: 'MULTI_SELECT', field_type: 'match' },
  groups: { search_type: 'MULTI_SELECT', field_type: 'match' },
  number: { search_type: 'NUMBER', field_type: 'number' },
  boolean: { search_type: 'BOOLEAN', field_type: 'boolean' }
};

const UNHANDLED_FIELD_TYPES = ['DIMENSION_2D', 'DIMENSION_3D'];

const MATCHING_TYPES = [
  {
    label: 'Match all',
    value: 'match'
  },
  {
    label: 'Match any',
    value: 'any'
  }
];

export default class SearchField extends Component {
  constructor(props) {
    super(props);
    const { option, value } = this.calculateValue(props);
    const isActive = Array.isArray(value)
      ? !!value.length
      : isValue(value) || props.inline;
    this.state = { option, value, expanded: isActive };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      isValue(this.state.value) &&
      !isEmpty(this.props.query.filters) &&
      isEmpty(nextProps.query.filters)
    ) {
      this.resetValue();
    }
    const { option, value } = this.calculateValue(nextProps);
    this.setState({ option, value });
  }

  calculateValue = props => {
    // Search type options relative to this field
    const searchTypeOptions = this.getSearchTypeOptions(props);
    // Query object relative to this field
    const queryValue = get(props.query, `filters.${props.fieldKey}`);
    // Current field value, search option used for this field
    let fieldValue, searchTypeOption;
    // Check if there's a initial query to preload the values
    if (queryValue) {
      searchTypeOption = searchTypeOptions.find(option =>
        Object.keys(queryValue).includes(option.value)
      );
      if (searchTypeOption) fieldValue = queryValue[searchTypeOption.value];
    }
    searchTypeOption = searchTypeOption || searchTypeOptions[0];
    return { value: fieldValue, option: searchTypeOption };
  };

  getSearchTypeOptions = props => {
    if (props.isCustom) {
      return SEARCH_TYPES[
        props.field.custom_field_definition_type || 'SHORT_TEXT'
      ];
    } else {
      return SEARCH_TYPES[
        get(FIELD_TYPES[props.fieldKey], 'search_type') || 'SHORT_TEXT'
      ];
    }
  };

  resetValue = () => {
    this.setState({ value: '' }, () => {
      this.onChange();
      if (this._geoSuggest) return this._geoSuggest.clear();
    });
  };

  getSuggestions = () => {
    const suggestedField = {};
    suggestedField.key = this.props.fieldKey;
    if (this.props.isCustom) {
      suggestedField.custom_type = this.props.field.type;
    }
    this.props.getSuggestions(suggestedField);
  };

  onChange = () => {
    const fieldType = this.props.field.custom_field_definition_type;
    const key = this.props.fieldKey;
    const method = this.state.option.value;
    const value = this.state.value;
    this.props.onUpdate(key, method, value, fieldType);
  };

  renderMatchingType = () => (
    <Select
      size="S"
      className="MatchingType"
      value={this.state.option.value}
      onChange={({ target: { value } }) =>
        this.setState(
          { option: { ...this.state.option, ...{ value } } },
          this.state.value ? this.onChange : undefined
        )
      }
    >
      {MATCHING_TYPES.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );

  renderFieldType = (field, key, label) => {
    const { placeholder, isCustom } = this.props;
    const isCustomField = isCustom || (field.key || '').includes('custom');

    if (field.label === 'Location' && field.data_type !== 'RESOURCE_SELECT') {
      return (
        <Geosuggest
          ref={el => (this._geoSuggest = el)}
          placeholder="Search location"
          initialValue={this.state.value}
          onSuggestSelect={debounce(geo => {
            const label = geo ? geo.label : '';
            this.setState({ value: label }, this.onChange);
          }, 300)}
          inputClassName="form-control small-input"
        />
      );
    }

    switch (field.data_type) {
      case 'SHORT_TEXT':
        if (field.suggestable) {
          return (
            <SuggestedTextInput
              field={key}
              suggestions={this.props.tags.suggestions[key] || []}
              fetching={this.props.tags.fetching}
              className="form-control small-input"
              placeholder={`Search ${label}`}
              getSuggestions={this.getSuggestions}
              onEnter={this.props.onEnter}
              value={this.state.value}
              onChange={debounce(
                (name, value) => this.setState({ value }, this.onChange),
                300
              )}
            />
          );
        }
        return (
          <DEPRECATED_TextInput
            key={key}
            placeholder={`Search ${label}`}
            value={this.state.value}
            className="form-control full-width small-input"
            onChange={debounce(
              (name, value) => this.setState({ value }, this.onChange),
              300
            )}
          />
        );
      case 'CURRENCY':
        return (
          <div className="NumberFilterSearch">
            <select
              className="form-control DropdownInput small-input"
              placeholder="Select"
              onChange={e => {
                const option = find(
                  SEARCH_TYPES[field.data_type],
                  s => s.value === e.target.value
                );
                this.setState(
                  { option, value: this.state.value || 10000 },
                  this.onChange
                );
              }}
              value={this.state.option.value}
            >
              <option value="" disabled default>
                Select
              </option>
              {SEARCH_TYPES[field.data_type].map(i => (
                <option key={i.value} value={i.value}>
                  {i.label}
                </option>
              ))}
            </select>
            <DEPRECATED_TextInput
              field={key}
              className="form-control small-input twoooo"
              value={convertToWholeUnit(this.state.value)}
              type="number"
              placeholder={`Search ${label}`}
              onEnter={this.props.onEnter}
              onChange={debounce((name, value) => {
                value = parseInt(convertToLowestDenomination(value));
                this.setState({ value }, this.onChange);
              }, 300)}
              highlightOnFocus
            />
          </div>
        );
      case 'PERCENTAGE':
        return (
          <div className="NumberFilterSearch">
            <select
              className="form-control DropdownInput small-input"
              placeholder="Select"
              onChange={e => {
                const option = find(
                  SEARCH_TYPES[field.data_type],
                  s => s.value === e.target.value
                );
                this.setState(
                  { option, value: this.state.value || 100 },
                  this.onChange
                );
              }}
              value={this.state.option.value}
            >
              <option value="" disabled default>
                Select
              </option>
              {SEARCH_TYPES[field.data_type].map(i => (
                <option key={i.value} value={i.value}>
                  {i.label}
                </option>
              ))}
            </select>
            <DEPRECATED_TextInput
              field={key}
              className="form-control small-input onennn"
              value={this.state.value}
              type="text"
              placeholder={`Search ${label}`}
              onEnter={this.props.onEnter}
              onChange={debounce(
                (name, value) =>
                  this.setState({ value: value || null }, this.onChange),
                300
              )}
              highlightOnFocus
            />
          </div>
        );
      case 'MEASUREMENT':
      case 'HEIGHT':
      case 'NUMBER':
        return (
          <div className="NumberFilterSearch">
            <select
              className="form-control DropdownInput small-input"
              placeholder="Select"
              onChange={e => {
                const option = find(
                  SEARCH_TYPES[field.data_type],
                  s => s.value === e.target.value
                );
                this.setState(
                  { option, value: this.state.value || 100 },
                  this.onChange
                );
              }}
              value={this.state.option.value}
            >
              <option value="" disabled default>
                Select
              </option>
              {SEARCH_TYPES[field.data_type].map(i => (
                <option key={i.value} value={i.value}>
                  {i.label}
                </option>
              ))}
            </select>
            <DEPRECATED_TextInput
              field={key}
              className="form-control small-input onennn"
              value={this.state.value}
              type="number"
              placeholder={`Search ${label}`}
              onEnter={this.props.onEnter}
              onChange={debounce(
                (name, value) =>
                  this.setState(
                    { value: parseInt(value) || null },
                    this.onChange
                  ),
                300
              )}
              highlightOnFocus
            />
          </div>
        );
      case 'BOOLEAN':
        return (
          <input
            type="checkbox"
            checked={this.state.value}
            onChange={e =>
              this.setState({ value: e.target.checked }, this.onChange)
            }
          />
        );
      case 'TAG_SELECT':
        return (
          <>
            <TagInput
              label={null}
              tags={this.state.value}
              field={key}
              placeholder={placeholder || 'Add a tag and press enter'}
              inputClassname="form-control small-input"
              onChange={value => {
                const formattedValue =
                  value &&
                  value.map(val => {
                    if (typeof val === 'string') {
                      // If just single string in array format back into correct system tag
                      const systemTag = find(
                        field.tagSuggestions,
                        t => t['tag'] === val
                      );
                      return systemTag;
                    } else {
                      return val;
                    }
                  });
                this.setState({ value: formattedValue }, this.onChange);
              }}
              suggestions={field.tagSuggestions}
              sortAlphabetically
            />
            {this.renderMatchingType()}
          </>
        );
      case 'RESOURCE_SELECT':
        return (
          <MultiResourceSelector
            disabled={false}
            initialValue={this.state.value}
            onSelect={value => {
              this.setState({ value }, this.onChange);
            }}
            query={{
              filters: {
                resource_type_model: { eq: 'LOCATION' }
              },
              order_by: { direction: 'asc', field: 'full_name' }
            }}
            inputStyle={{
              placeholder: 'Select location resource'
            }}
            allowMultiple
          />
        );
      case 'MULTI_SELECT':
        let suggestions = [];
        let values = [];
        if (isCustomField) {
          suggestions = chain(field.items || [])
            .orderBy(['order', 'asc'])
            .map(i => ({ name: i.value, value: i.value }))
            .value();
          values = this.state.value;
        } else {
          suggestions = (field.items || []).map(i => ({
            id: i.value,
            name: i.name
          }));
          values = (this.state.value || [])
            .map(id => suggestions.find(s => s.id === id))
            .filter(i => i);
        }
        return (
          <>
            <TagInput
              label={null}
              tags={values}
              field={key}
              placeholder={placeholder || 'Add a tag and press enter'}
              inputClassname="form-control small-input"
              suggestions={suggestions}
              onChange={(items = []) => {
                let value = [];
                if (isCustomField) {
                  value = items.map(i => i.tag || i);
                } else {
                  value = items.map(i => i.id);
                }
                this.setState({ value }, this.onChange);
              }}
              disableAdd
            />
            {
              this.props.fieldKey !== 'production_type_id' &&
                this.renderMatchingType() // production_type_id does not allow type matching
            }
          </>
        );
      case 'SINGLE_SELECT':
        const items = isCustomField
          ? chain(field.items || [])
              .orderBy(['order', 'asc'])
              .map(i => ({ name: i.value, value: i.value }))
              .value()
          : field.items || []; // Format: { value: 4, name: "Shoe" }

        return (
          <>
            <TagInput
              label={null}
              tags={this.state.value}
              field={key}
              placeholder={placeholder || 'Add an option and press enter'}
              inputClassname="form-control small-input"
              suggestions={items}
              onChange={(items = []) => {
                let value = [];
                value = items.map(i => i.tag || i);
                this.setState({ value }, this.onChange);
              }}
              disableAdd
            />
          </>
        );

      default:
        return (
          <DEPRECATED_TextInput
            field={key}
            value={this.state.value}
            className="form-control small-input"
            placeholder={`Search ${label}`}
            onChange={debounce(
              (name, value) => this.setState({ value }, this.onChange),
              300
            )}
          />
        );
    }
  };

  render() {
    const { field, fieldKey } = this.props;
    const { value } = this.state;
    const label = field.label;
    const isActive = Array.isArray(value) ? !!value.length : isValue(value);

    if (UNHANDLED_FIELD_TYPES.includes(field.data_type)) {
      return null;
    }

    return (
      <div
        className={classnames({ active: isActive }, ['SearchField'])}
        key={label}
      >
        <Grid
          onClick={() => {
            this.setState(prev => ({ expanded: !prev.expanded }));
          }}
          className="SearchField-selector Parent_hoverListener"
        >
          <GridCell className="SearchField-label">
            {isActive && (
              <span className="SearchField-activeIndicator circle" />
            )}{' '}
            {label}{' '}
          </GridCell>
          <GridCell
            width="auto"
            className={!isValue(value) ? 'Child_hoverListener' : ''}
          >
            {isValue(value) ? (
              <span className="form-label link" onClick={this.resetValue}>
                Clear
              </span>
            ) : (
              <img
                src="/images/icon_arrow_down_dark.svg"
                width="12px"
                className={this.state.expanded ? 'flip' : ''}
                alt=""
              />
            )}
          </GridCell>
        </Grid>
        {(this.state.expanded || this.state.inline) && (
          <div className="SearchField-input">
            {this.renderFieldType(field, fieldKey, label)}
          </div>
        )}
      </div>
    );
  }
}
