import { rd, RemoteData } from '@passionware/monads';
import { ReactNode } from 'react';
import Loading from 'v1/components/shared/placeholders/Loading/Loading';
import { DimmedContainer } from 'v4/shared/components/forms/input/primitvies/DimmedContainer';
import { SelectOptionEmpty } from 'v4/shared/components/forms/input/primitvies/SelectOptionEmpty';
import { SelectOptionError } from 'v4/shared/components/forms/input/primitvies/SelectOptionError';

export interface OptionsLayoutProps<T> {
  optionsState: RemoteData<T[]>;
  renderEmpty?: () => ReactNode;
  renderError?: (error: Error) => ReactNode;
  renderLoading?: () => ReactNode;
  renderOptions: (options: T[]) => ReactNode;
  dimmedContainerClassName?: string;
}

const defaultRenderers = {
  renderEmpty: () => <SelectOptionEmpty />,
  renderError: (error: Error) => <SelectOptionError message={error.message} />,
  renderLoading: () => <Loading />
};

export function OptionsLayout<T>({
  optionsState,
  renderEmpty = defaultRenderers.renderEmpty,
  renderError = defaultRenderers.renderError,
  renderLoading = defaultRenderers.renderLoading,
  renderOptions,
  dimmedContainerClassName
}: OptionsLayoutProps<T>) {
  if (rd.isAwaiting(optionsState)) return renderLoading();

  if (rd.isError(optionsState))
    return (
      <DimmedContainer shouldDim={false} className={dimmedContainerClassName}>
        {renderError(optionsState.error)}
      </DimmedContainer>
    );

  return (
    <DimmedContainer
      shouldDim={rd.isPlaceholderData(optionsState)}
      className={dimmedContainerClassName}
    >
      {optionsState.data.length === 0
        ? renderEmpty()
        : renderOptions(optionsState.data)}
    </DimmedContainer>
  );
}
