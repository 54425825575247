import React, { useState } from 'react';
import { Select, SingleCheckbox } from 'v1/components/shared/form';

import './TagsFilter.scss';
import { TagsField } from 'v5/byModel/tags/TagsField';
import { myServices } from 'services/services.connected.app';
import arrayMove from 'array-move';

const TagsFilter = ({ param, index, filter, filterChanged, removeFilter }) => {
  const [selectorVisible, setSelectorVisible] = useState(
    !!(filter?.any || filter?.match)
  );

  const handleRangeClicked = () => {
    setSelectorVisible(!selectorVisible);
    if (selectorVisible) {
      removeFilter();
    }
  };

  const tagNames = filter?.any ?? filter?.match ?? [];
  const condition = filter?.match ? 'match' : 'any';

  function applyTags(tags) {
    if (tags.length === 0) {
      removeFilter();
      return;
    }
    filterChanged({
      ...param,
      condition,
      value: tags
    });
  }

  return (
    <div className="ResourceFilter">
      <div
        key={index}
        className="DropdownButton-option"
        onClick={handleRangeClicked}
      >
        <SingleCheckbox
          className="GenericSearch-filter-checkbox DropdownButton-option-icon"
          onChange={handleRangeClicked}
          checked={selectorVisible}
        />
        <span className="DropdownButton-option-label">{param.label}</span>
      </div>
      {selectorVisible && (
        <div className="TagsFilter-content">
          <TagsField
            createButtonOnNewLine
            draggable={false}
            className="tw-w-80"
            allowCreate={false}
            services={myServices}
            tagType="shortlist"
            mode="edit"
            data={filter?.any ?? filter?.match}
            onAdd={newTag => {
              applyTags([...tagNames, newTag.tag]);
            }}
            onRemove={({ tag }) => {
              applyTags(tagNames.filter(t => t !== tag));
            }}
            onMove={(from, to) => {
              const tags = arrayMove(tagNames, from, to);
              applyTags(tags);
            }}
          />
          <Select
            size="S"
            className="MatchingType tw-mt-4"
            value={condition}
            onChange={({ target: { value } }) =>
              filterChanged({
                ...param,
                condition: value,
                value: [...tagNames] // when we don't recreate the array, changes are not detected :(
              })
            }
          >
            <option value="match">Match all</option>
            <option value="any">Match any</option>
          </Select>
        </div>
      )}
    </div>
  );
};

export default TagsFilter;
