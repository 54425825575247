import { cf } from '@easle/component-factory';
import classnames from 'classnames';
import { cloneElement, ReactElement } from 'react';
import { cn } from '../platform/dom/cn';

export const ItemPrimitive = cf.div<{
  isActive?: boolean;
  colorVariant?: 'normal' | 'danger';
}>(
  {
    className: ({ isActive, className, colorVariant = 'normal' }) =>
      cn(
        'group/item',
        'tw-flex tw-flex-row tw-items-center tw-gap-md',
        'tw-cursor-default tw-select-none tw-px-lg tw-py-md tw-text-sm tw-rounded',
        '[.first-child-rounded-top-none>&]:first:tw-rounded-t-none',
        isActive
          ? [
              {
                normal: [
                  'tw-bg-bg-emphasis tw-text-fg-surface',
                  'dark:tw-bg-gray-200 dark:tw-text-gray-900'
                ],
                danger: ['tw-bg-bg-danger-emphasis tw-text-fg-surface']
              }[colorVariant]
            ]
          : [
              {
                normal: [
                  'tw-bg-white tw-text-black aria-disabled:tw-text-fg-muted',
                  'dark:tw-bg-gray-800 dark:tw-text-gray-200 dark:aria-disabled:tw-text-gray-500'
                ],
                danger: [
                  'tw-bg-white tw-text-fg-danger aria-disabled:tw-text-fg-muted',
                  'dark:tw-bg-gray-800 dark:tw-text-red-400 dark:aria-disabled:tw-text-gray-500'
                ]
              }[colorVariant]
            ],
        className
      )
  },
  ['isActive', 'colorVariant']
);

export function ItemPrimitiveIcon({
  children
}: {
  children: ReactElement<{ className?: string }>;
}) {
  return cloneElement(children, {
    className: classnames(children.props.className, 'tw-w-4 tw-h-4')
  });
}

export const ItemSecondaryText = cf.div({
  className:
    'tw-text-fg-muted tw-text-xs [[data-active=true]_&]:tw-text-fg-subtle dark:tw-text-gray-400 dark:[[data-active=true]_&]:tw-text-gray-500'
});
