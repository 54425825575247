import ReachPortal from '@reach/portal';
import Rect, { PRect } from '@reach/rect';
import classnames from 'classnames';
import React, { CSSProperties, KeyboardEventHandler, useEffect } from 'react';

import styles from './Portal.module.scss';

type PortalProps = {
  className?: string;
  background?: string;
  position?:
    | CSSProperties
    | ((rect: PRect | undefined) => CSSProperties | undefined);
  freezePage?: boolean;
  onClickOutside?(): void;
  children:
    | React.ReactNode
    | (({
        rect,
        ref
      }: {
        rect: PRect | null;
        ref: React.RefObject<HTMLDivElement>;
      }) => React.ReactNode);
};

const Portal = ({
  className,
  background,
  position = {},
  freezePage,
  onClickOutside,
  children
}: PortalProps) => {
  useEffect(() => {
    if (freezePage) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      if (freezePage) {
        document.body.style.removeProperty('overflow');
      }
    };
  }, [freezePage]);

  // NOTE: This is to ensure that if two frozen portals are open, the body stays frozen when one is unmounted
  useEffect(() => {
    if (freezePage && document.body.style.overflow !== 'hidden') {
      document.body.style.overflow = 'hidden';
    }
  });

  const onKeyUp: KeyboardEventHandler<HTMLDivElement> = event => {
    if (event.key === 'Escape') {
      // we have different libs that manage focus and interaction, they collide, ie. one is closing on kwydown, then focuses another element, then on keyup we close the other one
      const closePrevented = (window as any)
        .atellioV1PreventNextPortalCloseOnEscape;

      if (!closePrevented) {
        onClickOutside && onClickOutside();
      }
      delete (window as any).atellioV1PreventNextPortalCloseOnEscape;
    }
  };

  // NOTE: position is spread straight onto the style prop
  // so irl you can use this for dynamic backgrounds or whatever you want 🎨
  const getPosition = (rect: PRect | null): CSSProperties | undefined => {
    return rect
      ? typeof position === 'function'
        ? position(rect)
        : position
      : undefined;
  };

  return (
    <ReachPortal>
      <div
        className={styles.Wrapper}
        style={{ background }}
        onClick={onClickOutside}
        onKeyUp={onKeyUp}
      >
        <Rect>
          {({ rect, ref }) => (
            <div
              ref={ref}
              className={classnames(styles.Inner, className)}
              style={getPosition(rect)}
              onClick={e => e.stopPropagation()}
            >
              {typeof children === 'function'
                ? children({ rect, ref })
                : children}
            </div>
          )}
        </Rect>
      </div>
    </ReachPortal>
  );
};

export default Portal;
