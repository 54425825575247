import { get, has, map, pick } from 'lodash';
import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants.js';
import AuthService from 'lib/auth/AuthService';
import store from 'store';
import { openModal } from '../ui/ui.actions';

const token = true;

export function setShortlistUpdatedAt(shortlistId, updatedAt) {
  return {
    type: SHORTLISTS_ACTIONS.SET_SHORTLIST_UPDATED_AT,
    shortlistId,
    updatedAt
  };
}

export function getShortlists(query, event_id = null) {
  return {
    types: [
      SHORTLISTS_ACTIONS.GET_SHORTLISTS,
      SHORTLISTS_ACTIONS.GET_SHORTLISTS_SUCCESS,
      SHORTLISTS_ACTIONS.GET_SHORTLISTS_FAILURE
    ],
    promise: client =>
      client.post('/search/shortlists', { data: query, token }),
    event_id
  };
}

export function duplicateShortlist(id, event_id = null) {
  return {
    types: [
      SHORTLISTS_ACTIONS.DUPLICATE_SHORTLIST,
      SHORTLISTS_ACTIONS.DUPLICATE_SHORTLIST_SUCCESS,
      SHORTLISTS_ACTIONS.DUPLICATE_SHORTLIST_FAILURE
    ],
    promise: client => client.post(`/shortlists/${id}/duplicate`, { token }),
    event_id
  };
}

export function getShortlist(id, event_id = null) {
  return {
    types: [
      SHORTLISTS_ACTIONS.GET_SHORTLIST,
      SHORTLISTS_ACTIONS.GET_SHORTLIST_SUCCESS,
      SHORTLISTS_ACTIONS.GET_SHORTLIST_FAILURE
    ],
    promise: client => client.get(`/shortlists/${id}`, { token }),
    event_id
  };
}

export function getPublicShortlist(id, password, event_id = null) {
  return {
    types: [
      SHORTLISTS_ACTIONS.GET_PUBLIC_SHORTLIST,
      SHORTLISTS_ACTIONS.GET_PUBLIC_SHORTLIST_SUCCESS,
      SHORTLISTS_ACTIONS.GET_PUBLIC_SHORTLIST_FAILURE
    ],
    promise: client =>
      client.get(`/public/shortlists/${id}`, {
        params: { password: password || '' }
      }),
    event_id,
    public: true
  };
}

export function createShortlist(data) {
  const toSubmit = {
    ...data,
    public_title: data.title
  };

  return {
    types: [
      SHORTLISTS_ACTIONS.CREATE_SHORTLIST,
      SHORTLISTS_ACTIONS.CREATE_SHORTLIST_SUCCESS,
      SHORTLISTS_ACTIONS.CREATE_SHORTLIST_FAILURE
    ],
    promise: client => client.post('/shortlists', { data: toSubmit, token })
  };
}

export function batchAddShortlist({ shortlist_id }, data) {
  return {
    types: [
      SHORTLISTS_ACTIONS.BATCH_ADD_SHORTLIST,
      SHORTLISTS_ACTIONS.BATCH_ADD_SHORTLIST_SUCCESS,
      SHORTLISTS_ACTIONS.BATCH_ADD_SHORTLIST_FAILURE
    ],
    promise: client =>
      client.put(`/shortlists/${shortlist_id}/contacts/add`, { data, token })
  };
}

export function updateShortlist(id, data, event_id = null, contact_id) {
  const { shortlists } = store.getState();
  const { shortlistsUpdatedAt } = shortlists;
  const shortlistFromStore = shortlists.data[id];

  if (
    shortlistFromStore &&
    shortlistsUpdatedAt[id] &&
    new Date(shortlistsUpdatedAt[id]) > new Date(shortlistFromStore.updated_at)
  ) {
    return openModal('ShortlistUpdatedWarningModal', { shortlistId: id });
  }

  let toSubmit = pick(data, [
    'status',
    'title',
    'description',
    'default_resources_query',
    'public_title',
    'public_description',
    'private_password',
    'expanded',
    'tags',
    'enable_notifications',
    'public',
    'published',
    'enable_commenting',
    'enable_voting',
    'public_feedback',
    'default_fields',
    'layout',
    'archived',
    'show_availability'
  ]);

  if (data.shortlist_blocks) {
    let shortlist_blocks =
      data.shortlist_blocks &&
      data.shortlist_blocks.map(block => {
        if (typeof block.id === 'string' && block.id.includes('temp')) {
          delete block.id;
        }
        let shortlist_block = pick(block, [
          'id',
          'shortlist_id',
          'order',
          'data',
          'contact_id',
          'archived',
          'pins'
        ]);
        return shortlist_block;
      });

    toSubmit.shortlist_blocks = shortlist_blocks;
  }

  return {
    types: [
      SHORTLISTS_ACTIONS.UPDATE_SHORTLIST,
      SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_SUCCESS,
      SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_FAILURE
    ],
    promise: client =>
      client.put(`/shortlists/${id}`, { data: toSubmit, token }),
    event_id,
    contact_id
  };
}
export function createShortlistBlock(
  { shortlist_id, shortlist },
  data,
  event_id = null
) {
  const { shortlists } = store.getState();
  const { shortlistsUpdatedAt } = shortlists;
  const shortlistFromStore = shortlists.data[shortlist_id];

  if (
    shortlistFromStore &&
    shortlistsUpdatedAt[shortlist_id] &&
    new Date(shortlistsUpdatedAt[shortlist_id]) >
      new Date(shortlistFromStore.updated_at)
  ) {
    return openModal('ShortlistUpdatedWarningModal', {
      shortlistId: shortlist_id
    });
  }

  let shortlist_block = pick(data, ['contact_id', 'data', 'order', 'pins']);
  return {
    types: [
      SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK,
      SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_SUCCESS,
      SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_FAILURE
    ],
    promise: client =>
      client.post(`/shortlists/${shortlist_id}/associations/shortlist_blocks`, {
        data: shortlist_block,
        token
      }),
    event_id,
    shortlist_id,
    shortlist
  };
}
export function updateShortlistBlock(
  { shortlist_id, shortlist_block_id },
  data,
  event_id = null
) {
  let toSubmit = pick(data, ['contact_id', 'archived', 'data', 'id', 'order']);
  let pins =
    data.pins &&
    data.pins.map(pin => {
      if (typeof pin.id === 'string' && pin.id.includes('temp')) {
        delete pin.id;
      }
      let formattedPin = pick(pin, [
        'file_id',
        'pin_type',
        'url',
        'order',
        'id'
      ]);
      return formattedPin;
    });
  toSubmit.pins = pins;

  return {
    types: [
      SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_BLOCK,
      SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_BLOCK_SUCCESS,
      SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_BLOCK_FAILURE
    ],
    promise: client =>
      client.put(
        `/shortlists/${shortlist_id}/associations/shortlist_blocks/${shortlist_block_id}`,
        { data: toSubmit, token }
      ),
    event_id,
    shortlist_id,
    shortlist_block_id
  };
}
export function deleteShortlistBlock(
  { shortlist_id, shortlist_block_id, contact_id },
  event_id = null
) {
  return {
    types: [
      SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK,
      SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_SUCCESS,
      SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_FAILURE
    ],
    promise: client =>
      client.del(
        `/shortlists/${shortlist_id}/associations/shortlist_blocks/${shortlist_block_id}`,
        { token }
      ),
    event_id,
    shortlist_id,
    shortlist_block_id,
    contact_id
  };
}

// UNUSED
// export function createShortlistBlockPin(
//   { shortlist_id, shortlist_block_id },
//   data,
//   event_id = null
// ) {
//   return {
//     types: [
//       SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_PIN,
//       SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_PIN_SUCCESS,
//       SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_PIN_FAILURE
//     ],
//     promise: client =>
//       client.put(
//         `/shortlists/${shortlist_id}/associations/shortlist_blocks/${shortlist_block_id}`,
//         { data, token }
//       ),
//     event_id,
//     shortlist_id,
//     shortlist_block_id
//   };
// }

// UNUSED
// export function deleteShortlistBlockPin(
//   { shortlist_id, shortlist_block_id, pin_id },
//   data,
//   event_id = null
// ) {
//   return {
//     types: [
//       SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_PIN,
//       SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_PIN_SUCCESS,
//       SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_PIN_FAILURE
//     ],
//     promise: client =>
//       client.post(
//         `/shortlists/${shortlist_id}/associations/shortlist_blocks/${shortlist_block_id}`,
//         { token }
//       ),
//     event_id,
//     shortlist_id,
//     shortlist_block_id,
//     pin_id
//   };
// }

export function upvoteShortlistBlock(
  { shortlist_public_id, shortlist_id },
  data,
  event_id = null
) {
  const token = AuthService.token();
  return {
    types: [
      SHORTLISTS_ACTIONS.UPVOTE_SHORTLIST_BLOCK,
      SHORTLISTS_ACTIONS.UPVOTE_SHORTLIST_BLOCK_SUCCESS,
      SHORTLISTS_ACTIONS.UPVOTE_SHORTLIST_BLOCK_FAILURE
    ],
    promise: client =>
      client.post(
        `/public/shortlists/${shortlist_public_id}/associations/upvotes`,
        { data, token: token ? token.tokenString : '' }
      ),
    event_id,
    shortlist_id
  };
}

export function downvoteShortlistBlock(
  { shortlist_public_id, shortlist_id },
  data,
  event_id = null
) {
  const token = AuthService.token();
  return {
    types: [
      SHORTLISTS_ACTIONS.DOWNVOTE_SHORTLIST_BLOCK,
      SHORTLISTS_ACTIONS.DOWNVOTE_SHORTLIST_BLOCK_SUCCESS,
      SHORTLISTS_ACTIONS.DOWNVOTE_SHORTLIST_BLOCK_FAILURE
    ],
    promise: client =>
      client.post(
        `/public/shortlists/${shortlist_public_id}/associations/downvotes`,
        { data, token: token ? token.tokenString : '' }
      ),
    event_id,
    shortlist_id
  };
}

export function commentShortlistBlock(
  { shortlist_public_id, shortlist_id },
  data,
  event_id = null
) {
  const token = AuthService.token();
  return {
    types: [
      SHORTLISTS_ACTIONS.COMMENT_SHORTLIST_BLOCK,
      SHORTLISTS_ACTIONS.COMMENT_SHORTLIST_BLOCK_SUCCESS,
      SHORTLISTS_ACTIONS.COMMENT_SHORTLIST_BLOCK_FAILURE
    ],
    promise: client =>
      client.post(
        `/public/shortlists/${shortlist_public_id}/associations/comments`,
        { data, token: token ? token.tokenString : '' }
      ),
    event_id,
    shortlist_id
  };
}

export function deleteShortlist(id, event_id = null) {
  return {
    types: [
      SHORTLISTS_ACTIONS.DELETE_SHORTLIST,
      SHORTLISTS_ACTIONS.DELETE_SHORTLIST_SUCCESS,
      SHORTLISTS_ACTIONS.DELETE_SHORTLIST_FAILURE
    ],
    promise: client =>
      client.put(`/shortlists/${id}`, { data: { archived: true }, token }),
    event_id
  };
}

export function searchShortlistBlocks(id, query) {
  // Duplicated from searchContacts
  if (has(query, 'filters.tags')) {
    const formattedTags = map(get(query, 'filters.tags.match'), t =>
      typeof t === 'string' ? t : t.tag
    );
    query.filters.tags = { match: formattedTags };
  }
  return {
    types: [
      SHORTLISTS_ACTIONS.SEARCH_SHORTLIST_BLOCKS,
      SHORTLISTS_ACTIONS.SEARCH_SHORTLIST_BLOCKS_SUCCESS,
      SHORTLISTS_ACTIONS.SEARCH_SHORTLIST_BLOCKS_FAILURE
    ],
    promise: client =>
      client.post(`/shortlists/${id}/contacts/search`, { data: query }),
    id,
    public: true
  };
}
