function isElementDisabled(el: Element): el is Element & { disabled: boolean } {
  return !!('disabled' in el && el.disabled);
}

export function getSiblings(
  container: Element,
  startElement: Element,
  selector: string
) {
  const orderedElements = Array.from(container.querySelectorAll(selector));
  const currentElement = startElement.closest(selector);

  if (!(currentElement instanceof HTMLElement)) {
    throw new Error('startElement is not a HTML element');
  }

  const currentElementIndex = orderedElements.indexOf(currentElement);
  const nextElement = orderedElements[currentElementIndex + 1];
  const prevElement = orderedElements[currentElementIndex - 1];

  // Returns the next and previous focusable elements
  return { nextElement, prevElement };
}
